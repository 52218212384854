import React from "react"
import {buildPathPrefix} from "../../../utils/path-utils"
import {Link} from "../../../utils/url-optimizer";

const BlogPostNextPrev = ({ prevPost, nextPost, locale }) => {
const linkPrefix = buildPathPrefix(locale);

  let prevPostComponent = null;

  if (prevPost) {
    const formattedDatePrev = new Date(prevPost.date).toLocaleDateString('en-GB', {year:"numeric", month:"long", day:"numeric"})
    prevPostComponent = (
        <div className="prev-link-wrapper">
            <div className="info-prev-link-wrapper">
                <Link to={`${linkPrefix}/blog/${prevPost.slug}`}>
                    <span className="image-prev">
                        <img src={prevPost.image} alt="bb" />
                        <span className="post-nav-title">Prev</span>
                    </span>

                    <span className="prev-link-info-wrapper">
                        <span className="prev-title">{prevPost.title}</span>
                        <span className="meta-wrapper">
                            <span className="date-post">{formattedDatePrev}</span>
                        </span>
                    </span>
                </Link> 
            </div>
        </div>
    )
  } else {
    (<div className="prev-link-wrapper">
    </div>)
  }

  let nextPostComponent = null;

  if (nextPost) {
    const formattedDateNext = new Date(nextPost.date).toLocaleDateString('en-GB', {year:"numeric", month:"long", day:"numeric"})
    nextPostComponent = (

        <div className="next-link-wrapper ms-auto">
            <div className="info-next-link-wrapper">
                <Link to={`${linkPrefix}/blog/${nextPost.slug}`}>
                    <span className="next-link-info-wrapper">
                        <span className="next-title">{nextPost.title}</span>
                        <span className="meta-wrapper">
                            <span className="date-post">{formattedDateNext}</span>
                        </span>
                    </span>

                    <span className="image-next">
                        <img src={nextPost.image} alt="bb" />
                        <span className="post-nav-title">Next</span>
                    </span>
                </Link>
            </div>
        </div>
    )
} else {
    (<div className="next-link-wrapper">
    </div>)
}

  return (
    <React.Fragment>
        <div className="container position-relative pb-3 pb-md-8 pt-5">
          <div className="row align-items-center px-2 pb-8">
                  {prevPostComponent}
                  {nextPostComponent}
            </div>
        </div>
    </React.Fragment>
  )
}

export default BlogPostNextPrev
