import React from "react"
import logo from "../../../assets/images/tp-logo.svg"
import FadeLeftAnimation from "../../common/FadeLeftAnimation";

const BlogPostBanner = ({ draft, title, subheading, date, authorName, image, slug }) => {
const formattedDate = new Date(date).toLocaleDateString('en-GB', {year:"numeric", month:"long", day:"numeric"})
  return (
    <div className="contact-banner">
      <div className="container">
        <div className="row align-items-center main-banner-content ">
          <div className="col-12 col-lg-10">
            <div className="text-white innerpage-title">
              <FadeLeftAnimation>
                <h4 className="">{subheading}</h4>
              </FadeLeftAnimation>
              <FadeLeftAnimation>
                <h1 className="blogpost-heading text-primary display-5">{title}</h1>
              </FadeLeftAnimation>
              <FadeLeftAnimation>
                <h5 className="pt-6">{formattedDate} by {authorName}</h5>
              </FadeLeftAnimation>
            </div>
          </div>
          <div className="col-12 col-lg-2">
            <img src={logo} alt="service" className="blog-child-img overflow-hidden" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogPostBanner
