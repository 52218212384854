import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/global/Layout";
import BlogPostBanner from "../components/global/blogpost/BlogPostBanner";
import BlogPostBody from "../components/global/blogpost/BlogPostBody";
import BlogPostNextPrev from "../components/global/blogpost/BlogPostNextPrev";
import GetInTouch from "../components/global/forms/GetInTouch";
import { useLocale } from "../utils/use-country-location";

import { parseSeoFromQuery } from "../components/global/SEO";

export default function BlogPost(props) {
  const locale = useLocale();
  // Not ordered as in CMS! =/
  const data = props?.data?.markdownRemark?.frontmatter;
  const html = props?.data?.markdownRemark?.html;
  const posts = props?.data?.allMarkdownRemark?.edges.map(edge => ({
    ...edge?.node?.frontmatter,
  }));

  const currentPostIndex = posts.findIndex(post => post.slug === data.slug);
  const nextPost =
    currentPostIndex - 1 <= 0 ? undefined : posts[currentPostIndex - 1];
  const prevPost =
    currentPostIndex + 1 >= posts.length - 1
      ? undefined
      : posts[currentPostIndex + 1];

  const page = props?.pageContext?.page;

  return (
    <Layout {...parseSeoFromQuery(props.data)} page={page}>
      <BlogPostBanner {...data} />
      <BlogPostBody html={html} {...data} />
      <BlogPostNextPrev
        prevPost={prevPost}
        nextPost={nextPost}
        locale={locale}
      />
      <GetInTouch {...data?.getInTouch} />
    </Layout>
  );
}

export const query = graphql`
  query ($page: String, $locale: String, $slug: String) {
    markdownRemark(
      frontmatter: {
        page: { eq: $page }
        locale: { eq: $locale }
        slug: { eq: $slug }
      }
    ) {
      frontmatter {
        ...seo
        ...getInTouch
        draft
        title
        subheading
        date
        authorName
        image
        slug 
        tags
      }
      html
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { page: { eq: "blogpost" }, locale: { eq: $locale } }
      }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          frontmatter {
            draft
            title
            subheading
            date
            authorName
            image
            slug
          }
        }
      }
    }
  }
`;
