import React from "react"

const BlogPostBody = ({ draft, title, subheading, date, authorName, image, html, body, slug }) => {
  return (
    <React.Fragment>
      <div className="blog-above-area pt-4">
        <div className="container position-relative pb-3 pt-5 pt-md-6">
          <div className="row align-items-center px-2">
              <div className="col-md-9 mx-auto" >
                <div dangerouslySetInnerHTML={{ __html: html ?? body}} />
              </div>
          </div>
        </div> 
      </div>
    </React.Fragment>
  )
}

export default BlogPostBody
